import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import CurveHero from "../components/CurveHero"

const ProjectPage = ({data}) => {

    const orderedProjects = []
    for (let project of data.wp.nodes) orderedProjects[project.menuOrder-1] = project;

    return (
        <Layout>
        <div className="projects-wrapper bg-orange-100">
          <CurveHero pageTitle="Projects" location=""/>
          <div className="projects-container bg-orange-100">
              <ul className="bml-list-container">
                  {orderedProjects.map( (item,i) => (
                      <li key={i} className="mb-4 sm:mb-0">
                          <div className="project-bar">
                              <Link className="no-underline hover:text-gray-500" to={item.uri}>
                                <div className="image-wrap">
                                    <GatsbyImage image={item.project.content[0].img.gatsbyImage} />
                                </div>
                                <div className="text-content">
                                  <h5>{item.title + ', ' + item.project.content[0].stats.location}</h5>
                                </div>
                              </Link>
                          </div>
                      </li>
                  ))}
              </ul>
          </div>
        </div>
    </Layout>
    )
}

export const Head = () => <Seo title="Projects"/>

export const qry = graphql`
{
  wp: allWpPage(filter: {wpParent: {node: {slug: {eq: "projects"}}}}) {
    nodes {
      title
      uri
    	menuOrder
      project: ACFProjects {
        content: projectContent {
          ... on WpPage_Acfprojects_ProjectContent_TextAndImage {
            stats: projectStats {
              location
            }
            img: rightImage {
              gatsbyImage(width: 400, cropFocus: CENTER, fit: CONTAIN, aspectRatio: 1)
            }
          }
        }
      }
    }
  }
}

`

export default ProjectPage
